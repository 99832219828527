import IMask from 'imask';

export const phoneMask = IMask.createMask({
  mask: '+7 (000) 000-00-00',
});

export const formatMaskedPhone = (maskedPhone) => {
  return maskedPhone.replace(/ /g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '');
};

export const validateEmail = (email) => {
  if (!email) return false;
  return String(email)
    ?.toLowerCase()
    ?.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};
