import { Button, FileInput, Loader, Select, Space, Text, TextInput, Textarea } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DateInput } from '@mantine/dates';
import { useMutation, useQuery } from '@apollo/client';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { DayPricesInput } from '../../components/day-prices-input';
import { SalesInput } from '../../components/sales-input';
import { ADMIN_CREATE_OBJECT } from '../../gqls/object';
import { USERS } from '../../gqls/user';

export const CreateObjectPage = () => {
  const navigate = useNavigate();

  const [userId, setUserId] = useState(undefined);
  const [type, setType] = useState(undefined);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [square, setSquare] = useState(0);
  const [floor, setFloor] = useState(0);
  const [guestsCount, setGuestsCount] = useState(0);
  const [roomsCount, setRoomsCount] = useState(0);
  const [sleepCount, setSleepCount] = useState(0);
  const [files, setFiles] = useState([]);
  const [price, setPrice] = useState(0);
  const [dayPrices, setDayPrices] = useState([]);
  const [sales, setSales] = useState([]);
  const [checkinTime, setCheckinTime] = useState(undefined);
  const [checkoutTime, setCheckoutTime] = useState(undefined);
  const [startWorkDate, setStartWorkDate] = useState(undefined);
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('RU');
  const [longitude, setLongitude] = useState('');
  const [latitude, setLatitude] = useState('');

  const [userSearchValue, setUserSearchValue] = useState('');
  const [userSearch, setUserSearch] = useState('');

  const [loading, setLoading] = useState(false);

  const [createObject] = useMutation(ADMIN_CREATE_OBJECT);

  const { data: searchData, loading: searchLoading } = useQuery(USERS, {
    variables: {
      skip: 0,
      take: 10,
      search: userSearch ? userSearch : undefined,
    },
  });
  const users = searchData?.users || [];

  useEffect(() => {
    let timeout = setTimeout(() => {
      setUserSearch(userSearchValue);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [userSearchValue]);

  const handleCreateObject = async () => {
    if (!files.length) return;
    setLoading(true);
    let images = [];
    const formData = new FormData();
    for (let file of files) {
      formData.append('files', file);
    }
    try {
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch('/api/admin/uploads', {
        method: 'POST',
        body: formData,
        headers: { authorization: accessToken },
      });
      const json = await response.json();
      images = json?.filenames;
    } catch (err) {
      console.log(err);
      notifications.show({
        color: 'red',
        title: 'Ошибка',
        message: 'Произошла ошибка, повторите еще раз',
      });
      setLoading(false);
      return;
    }
    if (!images.length) return;
    const data = {
      type,
      title,
      description,
      square,
      floor,
      guestsCount,
      roomsCount,
      sleepCount,
      images,
      price,
      dayPrices: dayPrices.map((item) => ({
        day: item.day,
        price: item.price,
      })),
      sales: sales.map((item) => ({ amount: item.amount, days: item.days })),
      checkinTime,
      checkoutTime,
      startWorkDate: dayjs(startWorkDate).toISOString(),
      address,
      city,
      country,
      longitude: parseFloat(longitude),
      latitude: parseFloat(latitude),
      userId,
      status: 'new',
      unavailableDates: [],
    };
    createObject({ variables: { data } })
      .then(() => {
        navigate('/objects');
        notifications.show({ color: 'green', title: 'Объект добавлен' });
      })
      .catch((err) => {
        console.error(err);
        notifications.show({
          color: 'red',
          title: 'Ошибка',
          message: 'Произошла ошибка, повторите еще раз',
        });
      })
      .finally(() => setLoading(false));
  };

  const buttonDisabled =
    !userId ||
    !type ||
    !title ||
    !description ||
    !square ||
    !floor ||
    !guestsCount ||
    !roomsCount ||
    !files.length ||
    !price ||
    !checkinTime ||
    !checkoutTime ||
    !startWorkDate ||
    !address ||
    !city ||
    !country ||
    !longitude ||
    !latitude;

  return (
    <>
      <Text>Добавить объект</Text>
      <Space h="12px" />
      <Form>
        <Select
          label="Пользователь"
          searchable
          searchValue={userSearchValue}
          onSearchChange={(v) => {
            setUserSearch(undefined);
            setUserSearchValue(v);
          }}
          clearable
          nothingFoundMessage="Не найдено..."
          rightSection={searchLoading ? <Loader size="xs" /> : undefined}
          value={userId}
          onChange={(v) => setUserId(v)}
          data={users.map((item) => ({
            label: item.name || '',
            value: item.id || '',
          }))}
        />
        <Space h={12} />
        <Select
          label="Тип"
          data={[
            { value: 'flat', label: 'Квартира' },
            { value: 'studio', label: 'Студия' },
            { value: 'room', label: 'Комната' },
            { value: 'apartment', label: 'Апартамент' },
            { value: 'house', label: 'Дом' },
            { value: 'cottage', label: 'Коттедж' },
            { value: 'hostel', label: 'Хостел' },
            { value: 'hotel', label: 'Гостиница' },
            { value: 'guesthouse', label: 'Гостевой дом' },
          ]}
          value={type}
          onChange={(v) => setType(v)}
        />
        <Space h={12} />
        <TextInput label="Заголовок" value={title} onChange={(e) => setTitle(e.target.value)} />
        <Space h={12} />
        <Textarea label="Описание" value={description} onChange={(e) => setDescription(e.target.value)} />
        <Space h={12} />
        <TextInput
          label="Площадь (м2)"
          value={square}
          type="number"
          onChange={(e) => setSquare(parseFloat(e.target.value))}
        />
        <Space h={12} />
        <TextInput label="Этаж" value={floor} type="number" onChange={(e) => setFloor(parseInt(e.target.value))} />
        <Space h={12} />
        <TextInput
          label="Количество гостей"
          value={guestsCount}
          type="number"
          onChange={(e) => setGuestsCount(parseInt(e.target.value))}
        />
        <Space h={12} />
        <TextInput
          label="Количество комнат"
          value={roomsCount}
          type="number"
          onChange={(e) => setRoomsCount(parseInt(e.target.value))}
        />
        <Space h={12} />
        <TextInput
          label="Количество спальных мест"
          value={sleepCount}
          type="number"
          onChange={(e) => setSleepCount(parseInt(e.target.value))}
        />
        <Space h={12} />
        <FileInput label="Изображения" multiple value={files} onChange={(v) => setFiles(v)} />
        <Space h={12} />
        <TextInput label="Цена" value={price} type="number" onChange={(e) => setPrice(parseFloat(e.target.value))} />
        <Space h={12} />
        <DayPricesInput dayPrices={dayPrices} setDayPrices={setDayPrices} />
        <Space h={12} />
        <SalesInput sales={sales} setSales={setSales} />
        <Space h={12} />
        <Select
          label="Время заезда"
          value={checkinTime}
          onChange={(v) => setCheckinTime(v)}
          data={[...Array(24).keys()].map((item) => ({
            value: item > 9 ? `${item}:00` : `0${item}:00`,
            label: item > 9 ? `${item}:00` : `0${item}:00`,
          }))}
        />
        <Space h={12} />
        <Select
          label="Время выезда"
          value={checkoutTime}
          onChange={(v) => setCheckoutTime(v)}
          data={[...Array(24).keys()].map((item) => ({
            value: item > 9 ? `${item}:00` : `0${item}:00`,
            label: item > 9 ? `${item}:00` : `0${item}:00`,
          }))}
        />
        <Space h={12} />
        <DateInput label="Дата начала аренды" value={startWorkDate} onChange={(v) => setStartWorkDate(v)} />
        <Space h={12} />
        <TextInput label="Адрес" value={address} onChange={(e) => setAddress(e.target.value)} />
        <Space h={12} />
        <TextInput label="Город" value={city} onChange={(e) => setCity(e.target.value)} />
        <Space h={12} />
        <TextInput label="Страна" value={country} onChange={(e) => setCountry(e.target.value)} disabled />
        <Space h={12} />
        <TextInput label="Долгота" value={longitude} onChange={(e) => setLongitude(e.target.value)} type="number" />
        <Space h={12} />
        <TextInput label="Широта" value={latitude} onChange={(e) => setLatitude(e.target.value)} type="number" />
        <Space h={16} />
        <Button loading={loading} disabled={buttonDisabled} onClick={handleCreateObject}>
          Добавить
        </Button>
      </Form>
    </>
  );
};

const Form = styled.div`
  max-width: 500px;
`;
